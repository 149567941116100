import Arr = require("Everlaw/Core/Arr");
import Is = require("Everlaw/Core/Is");
import Base = require("Everlaw/Base");
import Dialog = require("Everlaw/UI/Dialog");
import Dom = require("Everlaw/Dom");
import Icon = require("Everlaw/UI/Icon");
import Perm = require("Everlaw/PermissionStrings");
import Recipient = require("Everlaw/Recipient");
import Rest = require("Everlaw/Rest");
import UI = require("Everlaw/UI");
import Util = require("Everlaw/Util");
import { IconButton } from "Everlaw/UI/Button";

export class DbPerm extends Base.Primitive<string> {
    override get className(): string {
        return "DbPerm";
    }
    override display(): string {
        return this.name;
    }
    override compare(other: Base.Object): number {
        // Overridden to display correct ordering:
        // Admin < Legal Holds < Upload < Delete (see below)
        if (Is.sameClass(this, other)) {
            const perms = Base.get(DbPerm);
            const thisIndex = perms.indexOf(this);
            const otherIndex = perms.indexOf(<DbPerm>other);
            if (thisIndex === -1 || otherIndex === -1) {
                // Fallback in-case cannot find
                return super.compare(other);
            } else {
                return thisIndex - otherIndex;
            }
        } else {
            // Fallback if comparing two different types
            return super.compare(other);
        }
    }
}

// Order added determines compare order for DbPerm
Base.add(new DbPerm(Perm.DB_ADMIN, "Admin"));
Base.add(new DbPerm(Perm.DB_LEGAL_HOLD, "Legal Holds"));
Base.add(new DbPerm(Perm.DB_UPLOAD, "Upload"));
Base.add(new DbPerm(Perm.DB_DELETE, "Delete"));

/**
 * A ProjectPermSet is a Base.Primitive representing a project-level permission that controls
 * user access to a class of objects.
 */
export class ProjectPermSet extends Base.Primitive<string> {
    override get className() {
        return "ProjectPermSet";
    }
    constructor(
        id: string,
        name: string,
        public receivePerm: string,
    ) {
        super(id, name);
    }
    objsDisplay() {
        return this.name;
    }
}

class StorybuilderProjectPermSet extends ProjectPermSet {
    override objsDisplay() {
        return super.objsDisplay() + " objects";
    }
}

export const ASSIGNMENT_GROUPS_PERMSET_ID = "ASSIGNMENT_GROUPS";
export const PREDICTION_MODELS_PERMSET_ID = "PREDICTION_MODELS";
export const SEARCH_TERM_REPORTS_PERMSET_ID = "SEARCH_TERM_REPORTS";
export const STORYBUILDER_PERMSET_ID = "STORYBUILDER";

Base.add(
    new ProjectPermSet(
        ASSIGNMENT_GROUPS_PERMSET_ID,
        "Assignment groups",
        Perm.RECEIVE_ASSIGNMENT_GROUPS,
    ),
);
Base.add(
    new ProjectPermSet(
        PREDICTION_MODELS_PERMSET_ID,
        "Prediction models",
        Perm.RECEIVE_PREDICTION_MODELS,
    ),
);
Base.add(
    new ProjectPermSet(SEARCH_TERM_REPORTS_PERMSET_ID, "Search term reports", Perm.RECEIVE_STRS),
);
Base.add(
    new StorybuilderProjectPermSet(
        STORYBUILDER_PERMSET_ID,
        "Storybuilder",
        Perm.RECEIVE_STORYBUILDER,
    ),
);

export enum ProjectRole {
    PROJECT_READ = "PROJECT_READ",
    PROJECT_ADMIN = "PROJECT_ADMIN",
}

export enum SystemRole {
    USER = "USER",
    ENG_ADMIN = "ENG_ADMIN",
    SUPERUSER = "SUPERUSER",
    CX_ADMIN = "CX_ADMIN",
    PROD_ADMIN = "PROD_ADMIN",
    CLIENT_DATA_ACCESS_REQUESTOR = "CLIENT_DATA_ACCESS_REQUESTOR",
    CLIENT_DATA_ACCESS_GRANTOR = "CLIENT_DATA_ACCESS_GRANTOR",
    SYS_ADMIN = "SYS_ADMIN",
    FIN_ADMIN = "FIN_ADMIN",
}

abstract class BaseRole<T extends string> extends Base.Primitive<T> {
    constructor(
        id: T,
        name: string,
        public order: number,
    ) {
        super(id, name);
    }
    override compare(other: BaseRole<T>): number {
        return this.order - other.order;
    }
}

export class ProjectRolePrimitive extends BaseRole<ProjectRole> implements Recipient {
    override get className(): string {
        return "ProjectRole";
    }
    sid(): ProjectRole {
        return this.id;
    }
    recipientType(): string {
        return "role";
    }
}
Base.add(new ProjectRolePrimitive(ProjectRole.PROJECT_READ, "All users", 0));
Base.add(new ProjectRolePrimitive(ProjectRole.PROJECT_ADMIN, "All project admins", 1));

export class SystemRolePrimitive extends BaseRole<SystemRole> {
    override get className(): string {
        return "SystemRole";
    }
}
Base.add(new SystemRolePrimitive(SystemRole.USER, "User", 0));
Base.add(new SystemRolePrimitive(SystemRole.SUPERUSER, "Superuser", 1));
Base.add(new SystemRolePrimitive(SystemRole.ENG_ADMIN, "Eng Admin", 2));
Base.add(new SystemRolePrimitive(SystemRole.CX_ADMIN, "CX Admin", 3));
Base.add(new SystemRolePrimitive(SystemRole.PROD_ADMIN, "Prod Admin", 4));
Base.add(
    new SystemRolePrimitive(SystemRole.CLIENT_DATA_ACCESS_REQUESTOR, "Client Data Requestor", 5),
);
Base.add(new SystemRolePrimitive(SystemRole.CLIENT_DATA_ACCESS_GRANTOR, "Client Data Grantor", 6));
Base.add(new SystemRolePrimitive(SystemRole.SYS_ADMIN, "System Management Admin", 7));
Base.add(new SystemRolePrimitive(SystemRole.FIN_ADMIN, "Fin Admin", 8));

/**
 * Do any of the specified sids have the specified permission on the specified object? The
 * permission should be a string from Security.ts that is appropriate for the given object. The
 * default object is the current project.
 *
 * The fullSecurity and readSecurity fields include implicit permissions due to roles, group
 * membership, folder permissions, etc. For example, if a user belongs to a group that has
 * permissions on the object, the fullSecurity and readSecurity fields reflect the fact that the
 * user's SID has permissions on the object.
 */
export function can(sids: string | string[], perm: string, obj: Base.Secured) {
    if (!obj) {
        return false;
    }
    if (!!obj.fullSecurity) {
        return Arr.wrap(sids).some((sid) => Arr.contains(obj.fullSecurity[sid] || [], perm));
    }
    if (!!obj.readSecurity && perm === Perm.READ) {
        return Arr.wrap(sids).some((sid) => Arr.contains(obj.readSecurity, sid));
    }
    return false;
}

/**
 * Returns a Promise that resolves when the readSecurity of the specified object has been fetched.
 */
export function getReadSecurity(obj: Base.SecuredObject, force = false) {
    if (!!obj.readSecurity && !force) {
        return Promise.resolve();
    }
    return Rest.get("shareableObject/getReadSecurity.rest", {
        objectClass: obj.className,
        objectId: obj.id,
    }).then((data: { readSecurity: string[] }) => {
        obj.readSecurity = data.readSecurity;
        Base.publish(obj);
    });
}

// dialogs for invalid permission actions

export const REMOVE_USER_ACTION_TITLE = "Remove user from project";
export const REMOVE_DB_PERM_ACTION_TITLE = "Remove database permissions";
export const INVALID_ACTION_TITLE = "Cannot perform action";

export function showSelfReadRemovalDialog() {
    Dialog.ok(INVALID_ACTION_TITLE, "You cannot remove yourself from a project.");
}

export function showSelfProjectAdminRemovalDialog() {
    Dialog.ok(INVALID_ACTION_TITLE, "You cannot revoke your own administrative privileges.");
}

// This dialog should only be shown for superusers or org admins.
export function showLastGroupRemovalDialog() {
    Dialog.ok(INVALID_ACTION_TITLE, "You cannot delete the last group in the project.");
}

// This dialog should only be shown for superusers or org admins.
export function showNoAdminGroupDialog(onHide?: () => void) {
    Dialog.ok(
        "No Group with Project Admin Permissions",
        "This action has left the project without an Admin group. "
            + "Please correct this if it was unintentional.",
        onHide,
    );
}

/**
 * Generic message regarding the re-granting of RECEIVE permissions.
 */
export function regrantReceivePermsMsg(userString: string, obj = "these", objPlural = "objects") {
    return (
        "Should "
        + userString
        + " be re-granted "
        + obj
        + " permissions in the future, any "
        + objPlural
        + ' that have not been shared with "All Users" will have to be explicitly '
        + "re-shared before "
        + userString
        + " can access them again."
    );
}

export interface SharedObjectPermsEntryParams {
    info: Dom.Content;
    perms?: string[];
    isOwner: boolean;
    sid: string;
    className: string;
    id: string | number;
    revokable: boolean; // can be changed after creation by calling setRevokable
    onRevoke?: (data: any, sid: string) => void;
    // If not specified, the revoke icon will be hidden rather than grayed out.
    noRevokeTooltipContent?: Dom.Content;
}

/**
 * Class for creating a shared object permission entry. Used for viewing/editing the shared
 * permissions of a given object, as well as managing the shared object permissions of a user/group.
 */
export class SharedObjectPermsEntry {
    public node: HTMLElement;
    protected sid: string;
    protected className: string;
    protected id: string | number;
    private revokeIcon: IconButton;
    private noRevokeTooltipContent: Dom.Content;
    private toDestroy: Util.Destroyable[] = [];
    constructor(params: SharedObjectPermsEntryParams) {
        this.sid = params.sid;
        this.className = params.className;
        this.id = params.id;
        this.revokeIcon = new IconButton({
            iconClass: "x",
            tooltip: "Revoke permissions",
            onClick: () =>
                this.onRevoke().then((data: any) => {
                    this.destroy();
                    params.onRevoke && params.onRevoke(data, this.sid);
                }),
        });
        this.noRevokeTooltipContent = params.noRevokeTooltipContent;
        this.node = Dom.div(
            { class: "shared-object-perms-entry " + (params.className || "") },
            UI.alignedContainer({
                content: Dom.div(
                    Dom.span(params.info, params.perms ? ": " : ""),
                    Dom.span(
                        { class: "entry-perms" },
                        params.perms
                            ? params.perms.length
                                ? params.perms.join(", ")
                                : "none"
                            : "",
                        params.isOwner ? " (owner)" : "",
                    ),
                ),
                vertical: true,
            }),
            Dom.div({ class: "entry-revoke" }, Dom.node(this.revokeIcon)),
        );
        this.toDestroy.push(this.revokeIcon);
        this.setRevokable(!!params.revokable);
    }
    protected onRevoke() {
        return Rest.post("shareableObject/setPerms.rest", {
            sid: this.sid,
            objectClass: this.className,
            objectId: this.id,
        });
    }
    public setRevokable(revokable = true) {
        this.revokeIcon.setDisabled(!revokable);
        const show = revokable || !!this.noRevokeTooltipContent;
        Dom.show(this.revokeIcon, show);
        if (show) {
            Dom.setContent(
                this.revokeIcon.tooltip,
                revokable ? "Revoke permissions" : this.noRevokeTooltipContent,
            );
        }
    }
    public destroy() {
        Util.destroy(this.toDestroy);
        Dom.destroy(this.node);
        this.node = null;
    }
}

/**
 * Class for displaying assignment group self-assign permissions.
 */
export class SelfAssignPermsEntry extends SharedObjectPermsEntry {
    protected override onRevoke() {
        return Rest.post("unshareUnassigned.rest", {
            sid: this.sid,
            id: this.id,
        });
    }
}

export class OrgPermission extends Base.Primitive<string> {
    override get className(): string {
        return "OrgPermission";
    }
    override display() {
        return this.name;
    }
}
Base.add(new OrgPermission(Perm.ORG_ADMIN, "Admin"));
Base.add(new OrgPermission(Perm.CLOUD_MANAGEMENT_ADMIN, "Cloud Management"));
Base.add(new OrgPermission(Perm.LEGAL_HOLD_ADMIN, "Legal Holds"));
Base.add(new OrgPermission(Perm.AI_ADMIN, "AI Management"));

let orgPermissionDependencyTree: Map<OrgPermission, string[]> = null;

export function setOrgDependencyTable(table: { [perm: string]: string[] }): void {
    orgPermissionDependencyTree = new Map<OrgPermission, string[]>();
    Base.get(OrgPermission).forEach((perm) => {
        orgPermissionDependencyTree.set(perm, table[perm.id]);
    });
}

export function getOrgPermissionDependencyTable(): Map<OrgPermission, string[]> {
    return orgPermissionDependencyTree;
}
