import { ColorTokens } from "design-system";
import { ClientDataAccessRequest } from "Everlaw/AdminTabs/ClientDataAccessRequest";
import { Color, randomColorForId } from "Everlaw/ColorUtil";
import * as Enum from "Everlaw/Core/Enum";
import { defined } from "Everlaw/Core/Is";
import { isInternalOrg, MinimalOrganization, OrganizationId } from "Everlaw/MinimalOrganization";
import {
    CLOUD_MANAGEMENT_ADMIN,
    LEGAL_HOLD_ADMIN,
    ORG_ADMIN,
    AI_ADMIN,
} from "Everlaw/PermissionStrings";
import { OrgPermission, ProjectRole, SystemRole, SystemRolePrimitive } from "Everlaw/Security";
import { SystemPermission } from "Everlaw/SystemPermission";
import Base = require("Everlaw/Base");
import Arr = require("Everlaw/Core/Arr");
import Is = require("Everlaw/Core/Is");
import Database = require("Everlaw/Database");
import Dom = require("Everlaw/Dom");
import { OrgLabel } from "Everlaw/OrgLabel";
import MFA = require("Everlaw/MFA");
import Perm = require("Everlaw/PermissionStrings");
import Project = require("Everlaw/Project");
import Recipient = require("Everlaw/Recipient");
import Rest = require("Everlaw/Rest");
import Security = require("Everlaw/Security");
import TrainingActivity = require("Everlaw/TrainingActivity");
import Dialog = require("Everlaw/UI/Dialog");
import Util = require("Everlaw/Util");

var names: { [name: string]: User } = {};
var displayNames: { [disp: string]: User[] } = {};

class User extends Base.Object implements Base.Colored, Recipient {
    get className() {
        return "User";
    }
    // user data always provided by backend JSON
    override id: User.Id;
    name: string;
    firstName: string;
    lastName: string;
    primaryOrg: MinimalOrganization | null;
    orgs: MinimalOrganization[];
    orgPermissions: { [orgId: OrganizationId]: OrgPermission[] } = {};
    title: string;
    private labelIds: number[];
    explicitSystemRoles: SystemRole[];
    systemRoles: SystemRole[];

    /**
     * An Enum.Set of the SystemPermissions granted to this user (which must be User.me!). At
     * construction time, we initialize this value to an empty set, and it remains that empty for
     * non-me users. In _mixin, if the JSON includes systemPermissions, then we construct the proper
     * set of values.
     *
     * TODO: Create a separate UserMe class that extends User, so that we don't have to declare
     * properties like this for all users.
     */
    systemPermissions = Enum.Set.noneOf(SystemPermission);

    serviceAccount: boolean;
    activeAccessRequests: ClientDataAccessRequest[] = [];
    impersonatorUsername: string;
    // This is only defined on the profile page.
    canEditProfile: boolean;
    // This is only defined on org admin and superuser pages. Maps org ids to booleans.
    canRemoveFromOrg: Record<number, boolean>;
    // this flag lets us create a new User without impacting the naming of other users (i.e. by not adding that
    // user to the displayNames map and by displaying that's users name as [first] [last] (email))
    doNotAddToDisplayNames?: boolean;

    // included only when User.toJson's full parameter is set
    email: string;
    isEverlawyer: boolean;
    lastLoggedOut: number;
    joined: number;
    emailDowntimeAlert: boolean;
    announcementDowntimeAlert: boolean;
    mfaRequired: boolean;
    mfaDevice: MFA.MfaDevice;
    minPasswordLength: number;
    requirePasswordSpecial: boolean;
    requirePasswordUppercase: boolean;
    requirePasswordLowercase: boolean;
    requirePasswordNumeric: boolean;
    onDevWhitelist: boolean;
    trainingActivities: TrainingActivity[] = [];

    // Included only when calling projectUserToJson (i.e., for SingleProjectPageController).
    groups: User.GroupId[];
    projectRoles: ProjectRole[];
    expired: boolean;
    expiredGroups: User.GroupId[];

    isActiveInDb: boolean; // used by Database page

    // set to true when other users have the same display name
    disambiguate = false;

    static getSid(id: User.Id) {
        return id.toString();
    }

    constructor(params: any) {
        super(params);
        this._mixin(params);
    }
    override _mixin(params: any) {
        // name and display name could change on mixin so we take them out of
        // the current mappings.
        var oldDisp = this.displayName();
        if (!this.doNotAddToDisplayNames && oldDisp && oldDisp in displayNames) {
            Arr.remove(displayNames[oldDisp], this);
        }
        if (this.name) {
            delete names[this.name];
        }
        Object.assign(this, params); // take them all
        // Maintain the names mapping
        if (this.name) {
            names[this.name] = this;
        }
        // Ensure that the display name is unique
        // If not, add a flag to make the display name append the username
        var disp = this.displayName();
        if (disp && !this.doNotAddToDisplayNames) {
            var sameName = Util.getDefault(displayNames, disp, []);
            if (sameName.indexOf(this) < 0) {
                // We need to add this user now.
                sameName.push(this);
                if (sameName.length > 1) {
                    sameName.forEach(function (user) {
                        user.disambiguate = true;
                    });
                }
            }
        }
        this.primaryOrg = params.primaryOrg
            ? Base.get(MinimalOrganization, params.primaryOrg)
            : null;

        // NOTE: Filtering out non-found orgs in the next two collections, just in case of some
        // edge case or race where an org was deleted, but the user was not yet updated.
        this.orgs = params.orgs
            .map((id) => Base.get(MinimalOrganization, id))
            .filter((org) => !!org);

        // We only reset orgPermissions if param.orgPermissions is defined. See comment below about
        // activeAccessRequests.
        if (params.orgPermissions) {
            this.orgPermissions = {};

            params.orgPermissions.forEach((perm) => {
                const permissions: OrgPermission[] = [];
                perm.orgPermissions.forEach((permission) => {
                    permissions.push(Base.get(OrgPermission, permission));
                });
                this.orgPermissions[perm.orgId] = permissions;
            });
        }

        this.labelIds = params.labelIds;

        //Training activities are not always included in JSON
        if (params.trainingActivities) {
            this.trainingActivities = params.trainingActivities.map((ta) =>
                Base.get(TrainingActivity, ta),
            );
        }

        // We only serialize access requests as part of the "me" Json, since we only ever need them
        // for the current user. However, since User.me refers to a Base store object, it can
        // sometimes get updated in a "non-me" context. To avoid blowing away the access requests in
        // that case, we only reset them if params.activeAccessRequests is defined.
        //
        // This kind of thing is pretty clunky and error prone. Really, User.me should probably be a
        // singleton (with its own type) that we access from some kind of security context, not
        // treated equivalently to all the other Users.
        if (params.activeAccessRequests) {
            this.activeAccessRequests = [];
            params.activeAccessRequests.forEach((request) => {
                this.activeAccessRequests.push(new ClientDataAccessRequest(request));
            });
        }

        if (params.systemPermissions) {
            // The server serializes the permissions as a stringified BigInteger bit vector.
            this.systemPermissions = Enum.Set.fromBitVector(
                SystemPermission,
                params.systemPermissions as string,
            );
        }
    }
    isMemberOf(orgId: OrganizationId) {
        return this.orgs.some((o) => o.id === orgId);
    }
    isMemberOfAnyOrg() {
        return this.orgs.length > 0;
    }
    isAdminOf(orgId: OrganizationId): boolean {
        const orgAdmin = Base.get(OrgPermission, ORG_ADMIN);
        return this.hasOrgPermissionOn(orgId, orgAdmin);
    }
    // To configure or manage a particular org, a user must be an org admin on the org
    // or have the MANAGE_ORGANIZATIONS permission
    canAdminOrg(orgId: OrganizationId) {
        return (
            this.isAdminOf(orgId)
            || this.has(SystemPermission.MANAGE_ORGANIZATIONS)
            || this.hasEngAdminAccessToOrganization(orgId)
        );
    }

    canConfigureOrg(orgId: OrganizationId) {
        return this.has(SystemPermission.CONFIGURE_ORGANIZATIONS) || this.canAdminOrg(orgId);
    }

    isCloudManagementAdminOf(orgId: OrganizationId): boolean {
        const cloudManagementAdmin = Base.get(OrgPermission, CLOUD_MANAGEMENT_ADMIN);
        return this.hasOrgPermissionOn(orgId, cloudManagementAdmin);
    }
    isLegalHoldAdminOf(orgId: OrganizationId): boolean {
        const legalHoldAdmin = Base.get(OrgPermission, LEGAL_HOLD_ADMIN);
        return this.hasOrgPermissionOn(orgId, legalHoldAdmin);
    }
    isEverlawAIAdminOf(orgId: OrganizationId): boolean {
        const everlawAIAdmin = Base.get(OrgPermission, AI_ADMIN);
        return this.hasOrgPermissionOn(orgId, everlawAIAdmin);
    }
    hasOrgPermissionOn(orgId: OrganizationId, perm: OrgPermission): boolean {
        const orgPermissions = this.orgPermissions[orgId];
        if (!orgPermissions) {
            return false;
        }
        return orgPermissions.some((permission) => permission === perm);
    }
    hasAnyOrgPermissionOn(orgId: OrganizationId): boolean {
        const orgPermissions = this.orgPermissions[orgId];
        if (!orgPermissions) {
            return false;
        }
        return orgPermissions.length > 0;
    }
    orgsWithOrgPermissions(): OrganizationId[] {
        return Object.keys(this.orgPermissions).map(Number) as OrganizationId[];
    }
    getPermissionsOnOrg(orgId: OrganizationId): OrgPermission[] {
        if (orgId in this.orgPermissions) {
            return this.orgPermissions[orgId];
        }
        return [];
    }
    isAdminOfAnyOrg() {
        return this.orgsWithOrgPermissions().some((orgId) => this.isAdminOf(orgId));
    }
    orgsDisplay(max = 3) {
        const result = this.orgs
            .slice(0, max)
            .map((org) => org.display())
            .join(", ");

        return this.orgs.length > max ? `${result}, ...` : result;
    }
    primaryOrgDisplay(ifNone = "") {
        return this.primaryOrg ? this.primaryOrg.display() : ifNone;
    }
    inTheEverlawOrg(): boolean {
        return this.orgs.some((org) => org.isTheEverlawOrg());
    }
    isEverlawAdmin() {
        // Users with elevated privileges administer Everlaw.
        return this.hasElevatedRole() && !this.isEverlawUser();
    }
    // Is this user the unique Everlaw system user?
    isEverlawUser() {
        return this.hasElevatedRole() && this.name === "Everlaw";
    }
    override display() {
        if (this.isEverlawAdmin()) {
            return this.displayWithEverlawParenthetical();
        }
        // We'll just punt on the disambiguation if the user does not have a username. Hopefully
        // that is not the case for two different users.
        const dn = this.displayName();
        return this.disambiguate && this.name ? dn + " (" + this.name + ")" : dn;
    }
    displayWithEmail() {
        if (this.isEverlawAdmin()) {
            return this.displayWithEverlawParenthetical();
        }
        const dn = this.displayName();
        return dn === this.email ? dn : dn + " (" + this.email + ")";
    }
    displayWithPrimaryOrg() {
        const orgDisplay = this.primaryOrg ? " " + this.primaryOrgDisplay() : "";
        return this.display() + orgDisplay;
    }
    displayWithAbbrev(orgId = Project.CURRENT.owningOrganizationId) {
        if (!this.getLabelAbbrevs(orgId)) {
            return this.display();
        } else {
            return this.display() + " [" + this.getLabelAbbrevs(orgId) + "]";
        }
    }
    displayWithEverlawParenthetical() {
        // We want to indicate that users with elevated privileges are associated with Everlaw. This
        // should take care of disambiguation (assuming we don't have multiple Everlaw users with
        // the same name!)
        return this.displayName() + " (Everlaw)";
    }
    getOrgLabels(orgId: number) {
        return Base.get(OrgLabel, this.labelIds).filter((label) => label.orgId === orgId);
    }
    private labelHelper(orgId: number, labelOrAbbrev: (label: OrgLabel) => string) {
        return this.getOrgLabels(orgId)
            .map((userLabel) => labelOrAbbrev(userLabel))
            .filter((orgLabelName) => !!orgLabelName)
            .join(", ");
    }
    getLabels(orgId: number = Project.CURRENT.owningOrganizationId) {
        return this.labelHelper(orgId, (orgLabel) => {
            return orgLabel.identifier.trim();
        });
    }
    getLabelAbbrevs(orgId: number = Project.CURRENT.owningOrganizationId) {
        return this.labelHelper(orgId, (orgLabel) => {
            return orgLabel.abbreviation.trim();
        });
    }
    sid() {
        return User.getSid(this.id);
    }
    recipientType(): string {
        return "user";
    }
    /**
     * Returns true if the user has the specified permission on the specified object. The permission
     * should be a string from PermissionStrings.ts (imported as "Perm") that is appropriate for the
     * object. Superusers and org admins do not override by default. Org admins can override if
     * Project.CURRENT.orgAdminAccess is set or if they are on the project and an admin on the
     * project's database.
     *
     * TODO: This Override system would probably benefit from an overhaul now that we have system
     * permissions. Right now, the best we can do is to check MANAGE_PROJECTS and MANAGE_DATABASES,
     * but this method is called in a lot of contexts that shouldn't require such broad privileges.
     * Additionally, having a single generic `can` method for all Base.Secured objects makes this
     * code a bit more complicated and less clear than it might be otherwise. Instead, we could have
     * `can(perm, project, ...)`, `can(perm, database, ...)`, etc. We might also want to add a
     * a `Project.Secured` object--that is, a secured object that belongs to projects. This would
     * make it more clearly correct to check project-related permissions when `obj` isn't a project.
     */
    can(perm: string, obj: Base.Secured, override = User.Override.NONE) {
        if (!obj) {
            // e.g., could be invoked from a non-project page
            return false;
        }
        // TODO: This check seems potentially suspicious. For example, if a Database is passed in,
        // and the user has override on Project.CURRENT, this check immediately returns true,
        // without ever executing the DB-checking logic below. This is very likely fine with the way
        // that we use Override (and permissions are checked on the backend, anyway), but this is an
        // illustration of the TODO above, how this method could be a lot clearer if it wasn't
        // doing so much.
        if (this.hasOverride(override, obj instanceof Project ? obj : Project.CURRENT)) {
            return true;
        }
        if (
            obj instanceof Database
            && (this.hasOverrideOnDb(override, obj) || this.hasEngAdminAccessToDatabase(obj))
        ) {
            return true;
        }
        if (obj instanceof Project && this.hasEngAdminAccessToProject(obj)) {
            return true;
        }
        // If this is the current user, first check the 'security' field. Otherwise, call the
        // generic function that checks against the fullSecurity or readSecurity fields.
        if (this === User.me && Is.defined(obj.security)) {
            return Arr.contains(obj.security, perm);
        } else {
            return Security.can(this.sid(), perm, obj);
        }
        return false;
    }

    /**
     * Does the user have the given override permission on this project?
     */
    hasOverride(override: User.Override, project: Project = Project.CURRENT) {
        if (
            override & User.Override.ELEVATED
            && (this.has(SystemPermission.MANAGE_PROJECTS)
                // ENG_ADMINs should be able to do everything a superuser can do on an Everlaw project.
                || this.hasEngAdminAccessToProject(project)
                || this.hasGrantedAccessToProject(project))
        ) {
            return true;
        }
        if (override & User.Override.ORGADMIN && this.hasOrgAdminAccess(project)) {
            return true;
        }
        return false;
    }

    /**
     * Does the user have the given override permission on this database?
     */
    hasOverrideOnDb(override: User.Override, database: Database) {
        if (override & User.Override.ELEVATED && this.has(SystemPermission.MANAGE_DATABASES)) {
            return true;
        }
        if (override & User.Override.ORGADMIN && this.hasOrgAdminAccessToDb(database)) {
            return true;
        }
        return false;
    }

    /**
     * Does the user have the PROJECT_ADMIN role? The result of this call should be equivalent to
     * User.me.can(Perm.ADMIN, Project.CURRENT, User.Override.NONE).
     */
    isProjectAdmin() {
        return this.projectRoles && this.projectRoles.indexOf(ProjectRole.PROJECT_ADMIN) >= 0;
    }

    /**
     * @deprecated Checking whether a user is a superuser is an anti-pattern. Instead, we should
     * usually check User#has(SystemPermission). See the Java User#isSuperuser() for more
     * information. In some limited cases (e.g., when deciding whether to submit a GA event), we
     * only want to check whether this user has elevated privileges. In that case, we should call
     * User#hasElevatedRole().
     */
    isSuperuser() {
        return this.systemRoles.indexOf(SystemRole.SUPERUSER) >= 0;
    }

    /**
     * @deprecated Checking a user's roles to determine permissions is an anti-pattern. Instead, we
     * typically check User#has(SystemPermission) on some permission that is granted to an Eng
     * Admin.
     */
    hasEngAdminRole() {
        return this.systemRoles.indexOf(SystemRole.ENG_ADMIN) >= 0;
    }
    /**
     * Does this user have the ENG_ADMIN role as an explicit role?
     * Explicit roles don't include inherited roles.
     *
     * @deprecated Checking a user's roles to determine permissions is an anti-pattern. Instead, we
     * typically check User#has(SystemPermission) on some permission that is granted to an Eng
     * Admin.
     */
    hasExplicitEngAdminRole() {
        return this.explicitSystemRoles.indexOf(SystemRole.ENG_ADMIN) >= 0;
    }

    /**
     * @deprecated Checking a user's roles to determine permissions is an anti-pattern. Instead, we
     * typically check User#has(SystemPermission) on some permission that is granted to a CX Admin.
     */
    hasCxAdminRole(): boolean {
        return this.systemRoles.indexOf(SystemRole.CX_ADMIN) >= 0;
    }
    /**
     * Does this user have the CX_ADMIN role as an explicit role?
     * Explicit roles don't include inherited roles.
     *
     * @deprecated Checking a user's roles to determine permissions is an anti-pattern. Instead, we
     * typically check User#has(SystemPermission) on some permission that is granted to a CX Admin.
     */
    hasExplicitCxAdminRole(): boolean {
        return this.explicitSystemRoles.indexOf(SystemRole.CX_ADMIN) >= 0;
    }

    /**
     * @deprecated Checking a user's roles to determine permissions is an anti-pattern. Instead, we
     * typically check User#has(SystemPermission) on some permission that is granted to a Prod
     * Admin.
     */
    hasProdAdminRole(): boolean {
        return this.systemRoles.indexOf(SystemRole.PROD_ADMIN) >= 0;
    }
    /**
     * Does this user have the PROD_ADMIN role as an explicit role?
     * Explicit roles don't include inherited roles.
     *
     * @deprecated Checking a user's roles to determine permissions is an anti-pattern. Instead, we
     * typically check User#has(SystemPermission) on some permission that is granted to a Prod
     * Admin.
     */
    hasExplicitProdAdminRole(): boolean {
        return this.explicitSystemRoles.indexOf(SystemRole.PROD_ADMIN) >= 0;
    }

    /**
     * Does this user have the FIN_ADMIN role?
     *
     * @deprecated Checking a user's roles to determine permissions is an anti-pattern. Instead, we
     * typically check User#has(SystemPermission) on some permission that is granted to a Fin Admin.
     */
    hasFinAdminRole(): boolean {
        return this.systemRoles.indexOf(SystemRole.FIN_ADMIN) >= 0;
    }
    /**
     * Does this user have the FIN_ADMIN role as an explicit role?
     * Explicit roles don't include inherited roles.
     *
     * @deprecated Checking a user's roles to determine permissions is an anti-pattern. Instead, we
     * typically check User#has(SystemPermission) on some permission that is granted to a Fin Admin.
     */
    hasExplicitFinAdminRole(): boolean {
        return this.explicitSystemRoles.indexOf(SystemRole.FIN_ADMIN) >= 0;
    }

    /**
     * @deprecated Checking a user's roles to determine permissions is an anti-pattern. Instead, we
     * typically check User#has(SystemPermission) on some permission that is granted to a Sys Admin.
     */
    hasSysAdminRole(): boolean {
        return this.systemRoles.indexOf(SystemRole.SYS_ADMIN) >= 0;
    }
    /**
     * Does this user have the SYS_ADMIN role as an explicit role?
     * Explicit roles don't include inherited roles.
     *
     * @deprecated Checking a user's roles to determine permissions is an anti-pattern. Instead, we
     * typically check User#has(SystemPermission) on some permission that is granted to a Sys Admin.
     */
    hasExplicitSysAdminRole(): boolean {
        return this.explicitSystemRoles.indexOf(SystemRole.SYS_ADMIN) >= 0;
    }

    hasElevatedRole(): boolean {
        return this.systemRoles.some((role) => role !== SystemRole.USER);
    }

    systemRoleSummary(): string {
        if (this.systemRoles.length === 0) {
            return "None";
        } else if (this.hasElevatedRole()) {
            return "Privileged";
        } else {
            return Base.get(SystemRolePrimitive, SystemRole.USER).display();
        }
    }

    /**
     * @deprecated Use {@link UserUI.SystemRoleList}
     */
    systemRoleBulletedList(): HTMLUListElement {
        return Dom.ul(
            {},
            this.systemRoles.length === 0
                ? Dom.li("None")
                : this.systemRoles.map((role) => {
                      const roleName = Base.get(SystemRolePrimitive, role).display();
                      return this.explicitSystemRoles.indexOf(role) >= 0
                          ? Dom.li({ class: "bold" }, roleName)
                          : Dom.li(roleName);
                  }),
        );
    }

    /**
     * Does the user have the SUPERUSER role? Or are they a SUPERUSER that is currently
     * impersonating?
     *
     * @deprecated Checking whether a user is a superuser is an anti-pattern. Instead, we should
     * check a relevant SystemPermission or some other property of the user or page.
     */
    isSuperuserOrImpersonating(): boolean {
        return this.isSuperuser() || defined(this.impersonatorUsername);
    }
    /**
     * Does the user have the PROJECT_READ role? This should be true iff this.groups.length > 0.
     */
    isActiveUser() {
        return this.projectRoles && this.projectRoles.indexOf(ProjectRole.PROJECT_READ) >= 0;
    }

    /**
     * Returns true iff the user's email is used in place of their name.
     */
    isEmailUser() {
        return this.name === this.email;
    }

    /**
     * Do any of the user's active access requests give them permission for this project?
     */
    hasGrantedAccessToProject(project: Project): boolean {
        if (!project) {
            return false;
        }
        return this.activeAccessRequests.some((request: ClientDataAccessRequest) => {
            if (request.isProjectAccess() && request.entityId === project.id) {
                return true;
            }
            if (request.isDbAccess() && request.entityId === project.databaseId) {
                return true;
            }
            if (request.isOrgAccess() && request.entityId === project.owningOrganizationId) {
                return true;
            }
            return false;
        });
    }

    /**
     * @deprecated Checking a user's roles to determine permissions is an anti-pattern. This
     * permission check should be reworked to be based on User#has(SystemPermission) with some
     * permission that is granted to an Eng Admin.
     */
    hasEngAdminAccessToOrganization(orgId: OrganizationId): boolean {
        return this.hasEngAdminRole() && isInternalOrg(orgId);
    }

    /**
     * @deprecated Checking a user's roles to determine permissions is an anti-pattern. This
     * permission check should be reworked to be based on User#has(SystemPermission) with some
     * permission that is granted to an Eng Admin (or Sys Admin, as it turns out).
     */
    hasEngAdminAccessToProject(project: Project) {
        if (!project) {
            return false;
        }
        return (
            (this.hasSysAdminRole() && this.hasEngAdminAccessToDbId(project.databaseId))
            || this.hasEngAdminAccessToOrganization(project.owningOrganizationId)
        );
    }

    /**
     * @deprecated Checking a user's roles to determine permissions is an anti-pattern. This
     * permission check should be reworked to be based on User#has(SystemPermission) with some
     * permission that is granted to an Eng Admin (or Sys Admin, as it turns out).
     */
    hasEngAdminAccessToDatabase(database: Database) {
        if (!database) {
            return false;
        }
        if (this.hasEngAdminAccessToDbId(database.id)) {
            return true;
        }
        return this.hasEngAdminRole() && isInternalOrg(database.owningOrgId);
    }

    /**
     * Do any of the user's active access requests give them permission for this DB?
     */
    hasGrantedAccessToDb(database: Database): boolean {
        if (!database) {
            return false;
        }
        return this.activeAccessRequests.some((request: ClientDataAccessRequest) => {
            if (request.isProjectAccess()) {
                const proj = Base.get(Project, request.entityId);
                return proj && proj.databaseId === database.id;
            }
            if (request.isDbAccess() && request.entityId === database.id) {
                return true;
            }
            if (request.isOrgAccess() && request.entityId === database.owningOrgId) {
                return true;
            }
            return false;
        });
    }

    /**
     * @deprecated Checking a user's roles to determine permissions is an anti-pattern. This
     * permission check should be reworked to be based on User#has(SystemPermission) with some
     * permission that is granted to an Eng Admin (or Sys Admin, as it turns out).
     */
    hasEngAdminAccessToDbId(dbId: number) {
        return (
            this.hasSysAdminRole() && Arr.contains(JSP_PARAMS.EverlawAdmin.accessibleDbIds, dbId)
        );
    }

    /**
     * Does the user have Org Admin access to the specified database? In the case where the
     * orgAdminAccess flag is disabled, an org admin still has access if they are a database admin.
     */
    hasOrgAdminAccessToDb(database: Database) {
        return (
            !!database
            && this.isAdminOf(database.owningOrgId)
            && (database.orgAdminAccess || this.can(Perm.DB_ADMIN, database))
        );
    }
    /**
     * Does the user have Org Admin override access to the specified database or via elevated
     * privileges
     */
    hasOrgAdminOrElevatedAccessToDb(database: Database) {
        return (
            this.has(SystemPermission.MANAGE_DATABASES)
            || this.hasEngAdminAccessToDatabase(database)
            || this.hasOrgAdminAccessToDb(database)
        );
    }

    /**
     * Does the user have a role that allows them to access the client data access tab on the admin
     * page?
     *
     * @deprecated Checking a user's roles to determine permissions is an anti-pattern. Instead, we
     * typically check User#has(SystemPermission) on an appropriate permission. Unfortunately, this
     * particular method is called on users other than User.me, who do not have their system
     * permissions enumerated and serialized to the frontend. To remove this method, we may need to,
     * for example, update the callers to make a make a round-trip to the server to fetch the
     * desired users.
     */
    canViewClientDataAccessTab(): boolean {
        return (
            this.systemRoles.indexOf(SystemRole.CLIENT_DATA_ACCESS_GRANTOR) >= 0
            || this.systemRoles.indexOf(SystemRole.CLIENT_DATA_ACCESS_REQUESTOR) >= 0
        );
    }

    /**
     * At the moment, ARM uploads are an internal-only tool. The backend allows them for anyone who
     * can perform uploads, but we hide the functionality on the frontend. If/when we remove that
     * restriction, we can potentially delete this method entirely. For now, it serves as a
     * breadcrumb to the code that is currently gated.
     */
    canPerformArmUploads(): boolean {
        return User.me.isEverlawAdmin();
    }

    setOrgPerms(permissions: OrgPermission[], orgId: number): void {
        if (this.equals(User.me) && !User.me.has(SystemPermission.MANAGE_ORGANIZATIONS)) {
            Dialog.ok(Security.INVALID_ACTION_TITLE, "You cannot set your own org permissions.");
            return;
        }
        Rest.post("/users/modifyOrgPermissions.rest", {
            userId: this.id,
            orgId,
            permissions: permissions.map((perm) => perm.id),
        }).then((data) => {
            Base.set(User, data);
        });
    }

    /**
     * Returns true iff this user (which must be User.me) has the indicated system permission. The
     * system permissions of users other than User.me are not sent to the frontend. If this method
     * is called on some other user, it will always return false.
     *
     * TODO: Create a separate UserMe class that extends User, so that we don't have these confusing
     * caveats.
     */
    has(sysPerm: SystemPermission): boolean {
        return this.systemPermissions.has(sysPerm);
    }

    /**
     * Checks whether the user has the given database permission in either the current Project
     * context or on the given database. Therefore, the check is useful for situations where the
     * user may or may not be on the Project page or some non-Project page like the SU/OA pages.
     */
    hasDbPerm(dbPerm: string, database: Database, override = User.Override.NONE) {
        const onDb = this.can(dbPerm, database, override);
        const onProj = this.can(dbPerm, Project.CURRENT, override);
        return onDb || onProj;
    }

    /**
     * Does the user have Org Admin access to the specified project? This returns true if the user
     * is an org admin and either the orgAdminAccess flag is enabled or the user is a database admin
     * on the project's database.
     */
    hasOrgAdminAccess(project: Project = Project.CURRENT) {
        if (!project || !this.isAdminOf(project.owningOrganizationId)) {
            return false;
        }
        return project.orgAdminAccess || this.can(Perm.DB_ADMIN, project);
    }
    /**
     * Does the user have Org Admin override access to the specified project, have they been granted
     * project admin permissions, or do they have access via elevated privileges
     */
    hasOrgAdminOrElevatedAccess(project: Project = Project.CURRENT) {
        return (
            this.has(SystemPermission.MANAGE_PROJECTS)
            || this.hasEngAdminAccessToProject(project)
            || this.hasOrgAdminAccess(project)
            || this.hasGrantedAccessToProject(project)
        );
    }
    displayName() {
        var n = "";
        if (this.doNotAddToDisplayNames) {
            n = [this.firstName, this.lastName].filter((info) => info).join(" ");
            if (this.name) {
                n += n !== "" ? " " : ""; // if a space is needed before username
                n += "(" + this.name + ")";
            }
        } else if (this.firstName) {
            n = this.firstName + (this.lastName ? " " + this.lastName : "");
        } else if (this.lastName) {
            n = this.lastName;
        } else if (this.name) {
            n = this.name;
        } else if (this.email) {
            // The only way to reach this path is if we allow ourselves to create users with no
            // first or last name. If we never want to do that, we can delete this branch (and the
            // similar branch in User.java).
            n = this.email;
        }
        return n;
    }
    displayShort() {
        return this.firstName ? this.firstName : this.lastName ? this.lastName : this.name;
    }
    /**
     * Returns this user's system permissions as a string array. This is primarily useful for JS
     * debugging, and it also demonstrates how to work with the SystemPermission Enum.Set.
     */
    systemPermissionList(): string[] {
        return Array.from(this.systemPermissions).map((perm) => SystemPermission[perm]);
    }
    initials() {
        if (this.firstName && this.lastName) {
            return (this.firstName[0] + this.lastName[0]).toUpperCase();
        }
        return this.name[0].toUpperCase();
    }
    updateDbPerms(perms: string[]) {
        if (!this.equals(User.me) && Project.CURRENT.fullSecurity) {
            const projDbPerms = Project.CURRENT.fullSecurity[this.sid()] || [];
            const isNowAdmin = perms.indexOf(Perm.DB_ADMIN) >= 0;
            // Update fullSecurity for each permission
            Base.get(Security.DbPerm).forEach((perm) => {
                if (isNowAdmin || perms.indexOf(perm.id) >= 0) {
                    if (projDbPerms.indexOf(perm.id) < 0) {
                        projDbPerms.push(perm.id);
                    }
                } else {
                    Arr.remove(projDbPerms, perm.id);
                }
            });
            let newAcl: Base.ACL = {};
            newAcl[this.sid()] = projDbPerms;
            Project.updateFullSecurity(newAcl);
            Base.publish(this);
        }
    }
    setDbPerms(perms: string[], callback?: (u: User) => void) {
        if (this.equals(User.me)) {
            Dialog.ok(
                Security.INVALID_ACTION_TITLE,
                "You cannot set your own database permissions.",
            );
            return false;
        }
        Rest.post("setDbPerms.rest", { user: this.id, toGrant: perms }).then(() => {
            this.updateDbPerms(perms); // will do a Base.publish(this)
            callback && callback(this);
        });
    }
    getDbPerms(override = User.Override.NONE) {
        return Base.get(Security.DbPerm).filter((p) => this.can(p.id, Project.CURRENT, override));
    }
    hasDbPerms() {
        return this.getDbPerms().length > 0;
    }
    /**
     * Is the user in the specified group?
     */
    isInGroup(group: User.Group) {
        return this.groups && this.groups.indexOf(group.id) >= 0;
    }
    getColorHex() {
        if (this.isEverlawUser() || this.inTheEverlawOrg()) {
            // Everlaw is always special!
            return ColorTokens.EVERLAW;
        }
        if (!this.primaryOrg || User.numNonEverlawOrgs() <= 1) {
            // If this user doesn't have a primary organization, or there's only one organization in this
            // context (other than Everlaw), then color based on this user's id.
            return randomColorForId(this.id);
        }
        // Otherwise, color based on their primary organization.
        return this.primaryOrg.getColorHex();
    }
    private static numNonEverlawOrgs = Util.lazy(
        () => Base.get(MinimalOrganization).filter((org) => !org.isTheEverlawOrg()).length,
    );
    getColor() {
        return Color.fromHexString(this.getColorHex());
    }
    setMfaRequired(val: boolean, err?: () => void) {
        Rest.post("/users/setMfaRequired.rest", {
            req: val,
        })
            .then(() => {
                this.mfaRequired = val;
                Base.publish(this);
            })
            .catch(() => {
                err && err();
            });
    }
    setEmailDowntimeAlert(val: boolean, err?: () => void) {
        Rest.post("/users/setEmailDowntimeAlert.rest", {
            enabled: val,
        })
            .then(() => {
                this.emailDowntimeAlert = val;
                Base.publish(this);
            })
            .catch(() => {
                err && err();
            });
    }
    setAnnouncementDowntimeAlert(val: boolean, err?: () => void) {
        Rest.post("/users/setAnnouncementDowntimeAlert.rest", {
            enabled: val,
        })
            .then(() => {
                this.announcementDowntimeAlert = val;
                Base.publish(this);
            })
            .catch(() => {
                err && err();
            });
    }
    hasTraining(training: TrainingActivity) {
        return this.trainingActivities.indexOf(training) >= 0;
    }
    setTrainingActivities(
        toAdd: TrainingActivity[],
        toRemove: TrainingActivity[],
        sendSurvey: boolean,
    ) {
        if (toAdd.length || toRemove.length) {
            Rest.post("/users/setTrainings.rest", {
                id: this.id,
                toAdd: toAdd.map((ta) => ta.id),
                toRemove: toRemove.map((ta) => ta.id),
                sendSurvey,
            }).then((u) => Base.set(User, u));
        }
    }
}

module User {
    export type Id = number & Base.Id<"User">;
    /**
     * The current user. This value is null when the user is not logged in, so be careful to check
     * for that in places like Help and headHeader.
     */
    export var me: User = null;

    /**
     * Access User.me and rerender when the user state changes
     */
    export function useMe(): User {
        return Base.useStoreObject(Base.globalStore(User), User.me.id);
    }

    export var hasSSOEnabled: boolean = false;

    // Fetch a user by name; returns a User object for any user that has ever been on the project, not
    // just users that are currently active.
    export function byName(username: string) {
        return names[username];
    }

    /**
     * bit-wise overrides used by User#can
     */
    export enum Override {
        NONE = 0,
        /**
         * Override based on SystemPermission granted to user with elevated role(s), plus some
         * permissions explicitly granted to everlaw admins, such as client data access requests.
         */
        ELEVATED = 1 << 0,
        ORGADMIN = 1 << 1,
        ELEVATED_OR_ORGADMIN = Override.ELEVATED | Override.ORGADMIN,
    }

    export class Group extends Base.Object implements Recipient {
        get className() {
            return "Group";
        }
        override id: User.GroupId;
        name: string;
        deleted: boolean;
        constructor(params: any) {
            super(params);
            Object.assign(this, params);
        }
        override _mixin(params: any) {
            Object.assign(this, params);
        }
        override display() {
            return this.name + (this.deleted ? " (deleted)" : "");
        }
        rename(newName: string, callback?: Rest.Callback, error?: Rest.Callback) {
            Rest.post("groups/rename.rest", {
                group: this.id,
                name: newName,
            }).then(
                (data) => {
                    Base.set(User.Group, data);
                    callback && callback(data);
                },
                (e) => {
                    error && error(e);
                    throw e;
                },
            );
        }
        remove() {
            return Rest.post("groups/delete.rest", { group: this.id }).then(
                (data: { group: User.Group; usersRemoved: User[] }) => {
                    Base.set(User.Group, data.group);
                    Base.set(User, data.usersRemoved);
                },
            );
        }
        /**
         * Get the list of users in this group.
         */
        getUsers() {
            return Base.get(User).filter((u) => u.isInGroup(this));
        }
        initials() {
            return this.name ? this.name[0] : null;
        }
        /**
         * Does the group have the specified permission on the specified object?
         */
        can(perm: string, obj: Base.Secured) {
            return Security.can(this.sid(), perm, obj);
        }
        sid() {
            return Group.getSid(this.id);
        }
        recipientType(): string {
            return "group";
        }
        static getSid(id: GroupId) {
            return "GROUP_" + id;
        }
    }

    export type GroupId = number & Base.Id<"Group">;

    // See TODO in UserService#getSuperuserIds().
    export const superuserIds: { [id: number]: boolean } = {};

    export const orgAdminIds: { [id: number]: boolean } = {};

    /**
     * @param id id of user to display
     * @param unk fallback text for when there is no other reasonable display
     * @param displayAbbrev whether to include org label abbreviations in the display
     * @param orgId id of org to use when displaying the org name as a fallback, or when
     * including org label abbreviations with `displayAbbrev = true`. Defaults to the org of the
     * current project (or if not on a project level page, default to not displaying the org as a
     * fallback and not showing abbreviations).
     */
    export function displayById(
        id: User.Id,
        unk = "Unknown user",
        displayAbbrev = false,
        orgId?: OrganizationId,
    ) {
        orgId = orgId ?? Project.CURRENT?.owningOrganizationId;
        const userObj = Base.get(User, id);
        if (userObj) {
            return displayAbbrev && orgId ? userObj.displayWithAbbrev(orgId) : userObj.display();
        } else if (isSuperuser(id)) {
            return "Everlaw";
        } else if (User.orgAdminIds[id] && orgId) {
            return Base.get(MinimalOrganization, orgId).display();
        } else {
            return unk;
        }
    }

    /***
     * Show the user display and id, if available, or just the id if not.
     */
    export function adminDisplayById(id: User.Id): string {
        const userDisplay = Base.get(User, id)?.display();
        return userDisplay ? `${userDisplay} - ${id.toString()}` : id.toString();
    }

    // See TODO in UserService#getSuperuserIds().
    export function isSuperuser(id: User.Id) {
        return id in superuserIds;
    }

    export function canUploadToCurrentProject(override = Override.NONE) {
        // Uploaders need PARTIAL_PROJECT_DOCS permissions to upload to a partial project.
        return (
            User.me.can(Perm.DB_UPLOAD, Project.CURRENT, override)
            && (!Project.CURRENT.partial
                || User.me.can(Perm.PARTIAL_PROJECT_DOCS, Project.CURRENT, override))
            && User.me.can(Perm.FULL_DOC_ACCESS, Project.CURRENT, override)
        );
    }

    export function canProcessedUploadToCurrentProject(override = Override.NONE) {
        return User.canUploadToCurrentProject(override);
    }

    /**
     * Return the list of (partial) Projects to which the user can modify documents.
     */
    export function getModifiableProjects() {
        return Base.get(Project).filter((p) => {
            return (
                p.databaseId === Project.CURRENT.databaseId
                && p.partial
                && Project.keepVisible(p)
                && !p.suspended
                && !p.deletionRequested
                && User.me.can(Perm.PARTIAL_PROJECT_DOCS, p, User.Override.ELEVATED_OR_ORGADMIN)
            );
        });
    }
}

if (JSP_PARAMS.User) {
    Base.set(User, JSP_PARAMS.User.json);
    // This call must be done after the Base.set call above, since getMe fully serializes the
    // logged-in user, but getUsers does not.
    User.me = Base.set(User, JSP_PARAMS.User.me);
    User.hasSSOEnabled = JSP_PARAMS.User.hasSSOEnabled;
    JSP_PARAMS.User.superusers.forEach((id) => (User.superuserIds[id] = true));
    if (JSP_PARAMS.User.orgAdmins) {
        JSP_PARAMS.User.orgAdmins.forEach((id) => (User.orgAdminIds[id] = true));
    }
}

export = User;
